<template>
  <div class="footer-outer-wrapper">
    <footer class="footer-wrapper delay-entry delay-entry-quick" :class="{'centered': (!showSubmit || disableSubmitButton || isMandatory) && !showBackButton}" :key="`${submitText} + ${disableSubmitButton}`">
      <el-button
        type="warning"
        size="mini"
        @click="backOne()"
        class="delay-entryx unselectable align-left"
        v-if="showBackButton"
      >
        {{previousQuestionText}}
      </el-button>
      <div
        type="danger"
        size="mini"
        @click="skip()"
        class="button-skip delay-entryx skip unselectable"
        :class="{'skip-selected': skipped, 'align-left': showSubmit && !disableSubmitButton}"
        :disabled="disabled"
        v-if="!isMandatory && !showBackButton"
      >{{skipText || skipQuestionText}}</div>
      <el-button
        type="danger"
        @click="submit()"
        class="submit-button"
        :class="{hide: disableSubmitButton, 'delay-entryx': !disableSubmitButton}"
        v-if="showSubmit"
        :disabled="disableSubmitButton"
      >{{submitText || submitQuestionText}}
    </el-button>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'SkipQuestionButton',

  props: [ 'disabled', 'alignLeft', 'disableSubmitButton', 'showSubmit', 'isMandatory', 'submitText', 'skipText', 'showBackButton' ],

  data () {
    return {
      skipped: false
    }
  },

  methods: {
    skip () {
      this.skipped = true
      this.$emit('skip')
    },
    submit () {
      this.$emit('submit')
    },
    backOne () {
      this.$emit('previous')
    }
  },
  computed: {
    skipQuestionText () {
      if (this.$store.state.survey.language === 'EN') {
        return 'Skip question'
      }
      if (this.$store.state.survey.language === 'PT') {
        return 'Pular pergunta'
      }
      if (this.$store.state.survey.language === 'HIN') {
        return 'प्रश्न छोड़ें'
      }
      return 'Saltar pregunta'
    },
    submitQuestionText () {
      if (this.$store.state.survey.language === 'EN') {
        return 'Next'
      }
      if (this.$store.state.survey.language === 'PT') {
        return 'Seguir'
      }
      if (this.$store.state.survey.language === 'HIN') {
        return 'जारी रखें'
      }
      return 'Seguir'
    },
    previousQuestionText () {
      if (this.$store.state.survey.language === 'EN') {
        return 'Previous'
      }
      if (this.$store.state.survey.language === 'PT') {
        return 'Volver'
      }
      if (this.$store.state.survey.language === 'HIN') {
        return 'वापस'
      }
      return 'Volver'
    }
  }

}
</script>

<style scoped lang="scss">
.hide {
  opacity: 0.0001;
  max-width: 0 !important;
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}

footer {
  transition: all 0.2s ease-in-out;
  position: relative;
  height: 50px;
  width: 94%;
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;

  &.centered {
    justify-content: center;
  }

  @include breakpoint($tablet) {
    width: 94%;
  }
  @include breakpoint($desktop) {
    margin-bottom: 20px;
  }
  @include breakpointHeight($square) {
    margin-bottom: 10px;
    height: 40px;
  }

}

.button-skip {
  line-height: 1;
  height: 1.2em;
  background: none;
  color: $gray-dark;
  border: none;
  border-bottom: 1px solid $gray-dark;
  transition: all 0.1s ease-in-out;
  z-index: 0;
  position: relative;
  font-size: 18px;

  @include breakpoint($micro) {
    font-size: 16px;
  }
  @include breakpointHeight($square) {
    font-size: 15px;
  }
  &.skip-selected {
    border-bottom: 1px solid #fff;
    color: $gray-medium;
    font-size: 19px;
  }
}

.submit-button {
  z-index: 1;
  position: relative;
  @include breakpoint($micro) {
    padding: 5px;
  }
  @include breakpoint($desktop) {
    transform: scale(1);
  }
}

@keyframes standOut {
  0% { transform: scale(0.99); opacity: 0.0001; }
  20% { transform: scale(1.15) }
  100% { transform: scale(1.05); opacity: 1; }
}
</style>
