import { render, staticRenderFns } from "./SkipQuestionButton.vue?vue&type=template&id=0cd6ac34&scoped=true&"
import script from "./SkipQuestionButton.vue?vue&type=script&lang=js&"
export * from "./SkipQuestionButton.vue?vue&type=script&lang=js&"
import style0 from "./SkipQuestionButton.vue?vue&type=style&index=0&id=0cd6ac34&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cd6ac34",
  null
  
)

export default component.exports